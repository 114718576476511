import React from 'react';
import {SketchP5} from 'components/atoms';
import styles from './styles.module.scss';

const SketchContainer = ({title, paragraph, sketch}) => {
	return (
		<div className={styles.container}>
			<h4 className={'text-center'}>{title}</h4>
			{paragraph ? <p className={'text-center'}>{paragraph}</p> : ' '}
			<SketchP5 sketch={sketch} />
		</div>
	);
};

export default SketchContainer;
