import React from 'react';
import Loadable from '@loadable/component';
import Helmet from 'react-helmet';

const SketchP5 = ({sketch}) => {
	const P5Wrapper = Loadable(() => import('react-p5-wrapper'));

	return (
		<>
			<Helmet>
				<script src='https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.0.0/p5.js'></script>
			</Helmet>
			<P5Wrapper sketch={sketch} />
		</>
	);
};

export default SketchP5;
