import React from 'react';
import {GitHub, Twitter, Instagram, Figma} from 'react-feather';
import utilities from 'theme/utilities.module.scss';
import styles from './styles.module.scss';

const SocialLinks = () => {
	return (
		<ul
			className={`${styles.container} container ${utilities.maxWidthMedium}`}
		>
			<li>
				<a href='https://github.com/justinsunho'>
					<GitHub size={16} />
				</a>
			</li>
			<li>
				<a href='https://twitter.com/justinsunho'>
					<Twitter size={16} />
				</a>
			</li>
			<li>
				<a href='https://www.instagram.com/justinskim/'>
					<Instagram size={16} />
				</a>
			</li>
			<li>
				<a href='https://www.figma.com/@justinsunho'>
					<Figma size={16} />
				</a>
			</li>
		</ul>
	);
};

export default SocialLinks;
