import React from 'react';
import {SocialLinks} from 'components/molecules';
import styles from './styles.module.scss';

const Footer = () => {
	return (
		<footer className={`${styles.container}`}>
			<SocialLinks />
		</footer>
	);
};

export default Footer;
