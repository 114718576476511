import React from 'react';
import {Header, Footer} from 'components/organisms';
import styles from './styles.module.scss';
import utilities from 'theme/utilities.module.scss';

export default class Layout extends React.Component {
	render() {
		const {children} = this.props;

		return (
			<div className={styles.layout}>
				<Header />
				<main
					className={`${styles.content} container ${utilities.maxWidthMedium}`}
				>
					{children}
				</main>
				<Footer />
			</div>
		);
	}
}
