import React from 'react';
import {Link} from 'gatsby';
import styles from './styles.module.scss';

const Card = ({title, link, children}) => {
	return (
		<Link to={link} className={styles.container}>
			<h3 className={styles.title}>{title}</h3>
			<div className={styles.preview}>{children}</div>
		</Link>
	);
};

export default Card;
