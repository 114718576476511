import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import utilities from 'theme/utilities.module.scss';
import styles from './styles.module.scss';

const Header = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<header
			className={`${styles.container} container ${utilities.maxWidthMedium}`}
		>
			<Link
				activeClassName={styles.active}
				className={`${styles.siteName}`}
				to='/'
			>
				<h1>{data.site.siteMetadata.title}</h1>
			</Link>
			<ul className={styles.navigationContainer}>
				<li>
					<Link
						activeClassName={styles.active}
						className={styles.navLink}
						to='/about'
					>
						ABOUT
					</Link>
				</li>
			</ul>
		</header>
	);
};

export default Header;
