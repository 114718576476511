import React from 'react';
import styles from './styles.module.scss';

const TypeContainer = ({children, backgroundColor, description}) => {
	return (
		<div
			className={`col-12 ${styles.container}`}
			style={{backgroundColor: backgroundColor}}
		>
			{children}
			<p className={styles.description}>{description}</p>
		</div>
	);
};

export default TypeContainer;
